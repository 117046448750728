<template>
  <div id="edit-song-page" class="page-layout-wrapper">
    <Loader
      v-if="loading.general"
      :minheight="true"
    />
    <div
      v-else
      class="container-fluid"
    >
      <PageHeader
        :title="initialSong.title"
        :subtitle="initialSong.artist"
      />

      <div class="wav-form-container">
        <WavForm
          ref="wavform"
          :song="song"
          :autoplay="true"
        />
      </div>

      <div class="row justify-content-center">
        <div v-if="quizing" class="main mt-5 col-12 pl-5 pr-5">
          <div style="text-align: center" class="mb-5">
            <button @click="quizing = false" type="button" class="btn btn--sm">
              Back to Song Details
            </button>
          </div>
          <Quiz class="pb-5" @set-x="setX" :song="song"/>
        </div>
        <div v-else class="main mt-5 col-6 pl-5 pr-5">
          <div class="grouped mt-0">
            <div class="form-group">
              <label for="song_title" class="form-label">Title</label>
              <input type="text" class="form-control" id="song_title" v-model="song.title">
            </div>
            <div class="form-group">
              <label for="song_artist" class="form-label">Artist</label>
              <input type="text" class="form-control" id="song_artist" v-model="song.artist">
            </div>
            <div class="form-group">
              <label for="song_bpm" class="form-label">BPM</label>
              <input type="text" class="form-control" id="song_bpm" v-model="song.bpm">
            </div>
            <div class="form-group">
              <label for="song_size" class="form-label">Size (MB)</label>
              <input readonly="readonly" type="text" class="form-control" id="song_size" v-model="song.size">
            </div>
            <div class="custom-control custom-checkbox">
              <input class="custom-control-input" type="checkbox" v-model="song.one_stop" id="one_stop">
              <label class="custom-control-label form-label" for="one_stop">
                One-stop Disabled
              </label>
            </div>
          </div>
          <div class="grouped">
            <h5>Status</h5>
            <div class="custom-control custom-radio">
              <input class="custom-control-input" type="radio" name="song_status" id="song_publish" value="publish"
                     v-model="song.status">
              <label class="custom-control-label" for="song_publish">
                Publish
              </label>
            </div>
            <div class="custom-control custom-radio">
              <input class="custom-control-input" type="radio" name="song_status" id="song_draft" value="draft"
                     v-model="song.status">
              <label class="custom-control-label" for="song_draft">
                Draft
              </label>
            </div>
          </div>

          <div class="grouped">
            <h5>Pool Coordinates</h5>
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label for="song_x">X Coordinate</label>
                  <input type="text" class="form-control" id="song_x" v-model="song.x">
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label for="song_y">Y Coordinate</label>
                  <input type="text" class="form-control" id="song_y" v-model="song.y">
                </div>
              </div>
            </div>
            <button @click="quizing = true" type="button" class="btn">
              Take Quiz
            </button>
          </div>

          <div class="grouped">
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <h5>Mood</h5>
                  <input type="text" class="form-control" id="song_mood" :value="moodKeyword" readonly="readonly">
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <h5>Tempo</h5>
                  <input type="text" class="form-control" id="song_tempo" v-model="tempoKeyword" readonly="readonly">
                </div>
              </div>
            </div>
          </div>
          <div class="grouped">
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <h5>Form</h5>

                  <div class="custom-control custom-radio" v-for="(form) in keywords.song_forms">
                    <input class="custom-control-input" type="radio" name="song_form" :id="form.id" :value="form.id"
                           v-model="song.song_form">
                    <label class="custom-control-label" :for="form.id">
                      {{ form.label }}
                    </label>
                  </div>
                  <div class="custom-control custom-radio">
                    <input class="custom-control-input" type="radio" name="song_form" id="form_unselected" :value="null"
                           v-model="song.song_form">
                    <label class="custom-control-label" for="form_unselected">
                      Unselected
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <h5>Energy</h5>

                  <div class="custom-control custom-radio" v-for="(energy) in keywords.energies">
                    <input class="custom-control-input" type="radio" name="song_energy" :id="energy.id" :value="energy.id"
                           v-model="song.energy">
                    <label class="custom-control-label" :for="energy.id">
                      {{ energy.label }}
                    </label>
                  </div>
                  <div class="custom-control custom-radio">
                    <input class="custom-control-input" type="radio" name="song_energy" id="status_unselected" :value="null"
                           v-model="song.energy">
                    <label class="custom-control-label" for="status_unselected">
                      Unselected
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="grouped">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <h5>Genres</h5>

                  <div class="custom-control custom-radio" v-for="(genre) in keywords.genres">
                    <input class="custom-control-input" type="checkbox" name="song_genre[]" :id="genre.id" :value="genre.id" v-model="song.genres" />
                    <label class="custom-control-label" :for="genre.id">
                      {{ genre.label }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="grouped">
            <h5>Master Owners</h5>

            <div v-if="song.master_owners && song.master_owners.length">
              <div class="row">
                <div class="col-3">
                  <label>Name</label>
                </div>
                <div class="col-3">
                  <label>Email</label>
                </div>
                <div class="col-3">
                  <label>Split</label>
                </div>
              </div>
              <div class="row" v-for="(master_owner,i) in song.master_owners" :key="i">
                <div class="col-3">
                  <div class="form-group">
                    <label class="sr-only" :for="'maste_owner_'+i">Master Owner Name</label>
                    <input type="text" class="form-control" :id="'maste_owner_'+i" v-model="master_owner.name">
                  </div>
                </div>
                <div class="col-3">
                  <div class="form-group">
                    <label class="sr-only" :for="'maste_owner_'+i">Master Owner Email</label>
                    <input type="email" class="form-control" :id="'maste_owner_email_'+i" v-model="master_owner.email">
                  </div>
                </div>
                <div class="col-3">
                  <div class="form-group">
                    <label class="sr-only" :for="'maste_owner_split_'+i">Master Owner Split</label>
                    <input type="number" class="form-control" :id="'maste_owner_split_'+i"
                           v-model="master_owner.splitPercent">
                  </div>
                </div>
                <div class="col-3">
                  <button @click="removeMasterOwner(i)" type="button" class="btn btn-danger">
                    <span class="sr-only">Delete Song Master Owner</span>
                    <i class="far fa-trash-alt"></i>
                  </button>
                </div>
              </div>

              <button @click="addMasterOwner()" type="button" class="pill-button pill-button--outline">
                Add Master Owner
              </button>
            </div>
            <div v-else>
              <p><em class="text-muted">None</em></p>
              <a href="#" @click.prevent="addMasterOwner()" class="pill-button pill-button--outline">
                Add Master Owner
              </a>
            </div>
          </div>

          <div class="grouped">
            <h5>Publishers</h5>
            <div v-if="song.publishers && song.publishers.length">
              <div class="row">
                <div class="col-3">
                  <label>Name</label>
                </div>
                <div class="col-3">
                  <label>Email</label>
                </div>
                <div class="col-3">
                  <label>Split</label>
                </div>
              </div>
              <div class="row" v-for="(publisher,i) in song.publishers" :key="i">
                <div class="col-3">
                  <div class="form-group">
                    <label class="sr-only" :for="'song_publisher_'+i">Publisher Name</label>
                    <input type="text" class="form-control" :id="'song_publisher_'+i" v-model="publisher.name">
                  </div>
                </div>
                <div class="col-3">
                  <div class="form-group">
                    <label class="sr-only" :for="'song_publisher_email_'+i">Publisher Email</label>
                    <input type="email" class="form-control" :id="'song_publisher_email_'+i" v-model="publisher.email">
                  </div>
                </div>
                <div class="col-3">
                  <div class="form-group">
                    <label class="sr-only" :for="'song_publisher_split_'+i">Publisher Split</label>
                    <input type="number" class="form-control" :id="'song_publisher_split_'+i"
                           v-model="publisher.splitPercent">
                  </div>
                </div>
                <div class="col-3">
                  <button @click="removePublisher(i)" type="button" class="btn btn-danger">
                    <span class="sr-only">Delete Song Publisher</span>
                    <i class="far fa-trash-alt"></i>
                  </button>
                </div>
              </div>

              <button @click="addPublisher()" type="button" class="pill-button pill-button--outline">
                Add Publisher
              </button>
            </div>
            <div v-else>
              <p><em class="text-muted">None</em></p>
              <a href="#" @click.prevent="addPublisher()" class="pill-button pill-button--outline">
                Add Publisher
              </a>
            </div>
          </div>

          <div class="grouped">
            <h4>Writers</h4>
            <div v-if="song.writers && song.writers.length">
              <div class="row">
                <div class="col-3">
                  <label>Name</label>
                </div>
                <div class="col-3">
                  <label>Email</label>
                </div>
                <div class="col-3">
                  <label>Split</label>
                </div>
              </div>
              <div class="row" v-for="(writer,i) in song.writers" :key="i">
                <div class="col-3">
                  <div class="form-group">
                    <label class="sr-only" :for="'song_writer_'+i">Writer Name</label>
                    <input type="text" class="form-control" :id="'song_writer_'+i" v-model="writer.name">
                  </div>
                </div>
                <div class="col-3">
                  <div class="form-group">
                    <label class="sr-only" :for="'song_writer_email_'+i">Writer Email</label>
                    <input type="email" class="form-control" :id="'song_writer_email_'+i" v-model="writer.email">
                  </div>
                </div>
                <div class="col-3">
                  <div class="form-group">
                    <label class="sr-only" :for="'song_writer_split_'+i">Writer Split</label>
                    <input type="number" class="form-control" :id="'song_writer_split_'+i"
                           v-model="writer.splitPercent">
                  </div>
                </div>
                <div class="col-3">
                  <button @click="removeWriter(i)" type="button" class="btn btn-danger">
                    <span class="sr-only">Delete Song Writer</span>
                    <i class="far fa-trash-alt"></i>
                  </button>
                </div>
              </div>
              <button @click="addWriter()" type="button" class="pill-button pill-button--outline">
                Add Writer
              </button>
            </div>
            <div v-else>
              <p><em class="text-muted">None</em></p>
              <a href="#" @click.prevent="addWriter()" class="pill-button pill-button--outline">
                Add Writer
              </a>
            </div>
          </div>

          <div class="row mt-5">
            <div class="col-6">
              <button
                @click="Save"
                type="button"
                class="btn btn-block btn-primary"
                :disabled="loading.save"
                v-text="loading.save ? 'Saving...' : 'Save'"
              />
            </div>
          </div>
        </div>

        <div v-if="!quizing" class="side mt-5 col-3">
          <h4>Playlists</h4>
          <b-button size="sm" v-b-modal.modal-edit-playlists>Open Playlist Manager</b-button>

          <br/>
          <br/>

          <h4>Revisions</h4>
          <ul
            v-if="orderedRevisions.length"
            class="revisions-list"
          >
            <li
              v-for="(revision, i) in orderedRevisions"
              :key="i"
              :class="[
                                'revision',
                                { 'active': revision.active == 1 }
                            ]"
            >
                            <span
                              :class="[
                                    'pill',
                                    { 'pill--outline': revision.active == 1 }
                                ]"
                              v-text="getDate(revision.created_at)"
                            />

              <button
                v-if="revision.active == 0"
                @click="activateRevision(revision)"
                type="button"
                class="restore-revision pill-button pill-button--outline"
              >
                Restore
              </button>
            </li>
          </ul>
          <div
            v-else
            class="text-muted"
          >
            <em>None</em>
          </div>
        </div>
      </div>
    </div>

    <b-modal v-if="song" id="modal-edit-playlists" size="xl" title="Manage Song Playlists" centered>
      <SongPlaylists :song="song"></SongPlaylists>
    </b-modal>
  </div>
</template>

<script>
import ListSongs from '@/components/Songs/List'
import Quiz from '@/components/Songs/Quiz'
import moment from 'moment-timezone'
import WavForm from '@/components/Songs/WavForm'
import Loader from '@/components/Loader'
import SongPlaylists from '@/components/Songs/Playlists'
import PageHeader from '@/components/Page/PageHeader'

export default {
  name: 'Edit',
  components: {
    SongPlaylists,
    Loader,
    Quiz,
    WavForm,
    ListSongs,
    PageHeader
  },
  data: () => ({
    quizing: false,
    loading: {
      general: false,
      save: false,
    },
    keywords: {
      all: {}
    },
    initialSong: null,
    song: null,
    revisions: [],
  }),
  async created () {
    this.loading.general = true

    await this.$http.get('/keywords').then(({data}) => {
      Object.keys(data).forEach((keywordGroup) => {
        this.keywords[keywordGroup] = [];

        data[keywordGroup].forEach((keyword) => {
          this.keywords['all'][keyword.id] = keyword.label;
          this.keywords[keywordGroup].push(keyword);
        });
      });
    });

    await this.$http
      .get('/admin/songs/' + this.$route.params.id)
      .then(({ data }) => {
        this.initialSong = data.song
        this.song = data.song

        this.$http.get(`/admin/revisions/${this.initialSong.id}`)
          .then(({ data }) => {
            this.revisions = data.revisions
            this.loading.general = false
          })
      });
  },
  computed: {
    tempoKeyword() {
      return this.keywords.all[this.song.tempo];
    },
    moodKeyword() {
      return this.keywords.all[this.song.mood];
    },
    orderedRevisions () {
      return this.revisions.reverse()
    },
    size: {
      set (val) {
        return
        this.song.size = val
      },
      get () {
        return (parseInt(this.song.size) / 1000000).toFixed(0)
      },
    },
  },
  methods: {
    activateRevision (revision) {
      let active_index = this.revisions.findIndex((object) => {
        return object.active == '1'
      })

      this.revisions[active_index].active = 0

      revision.active = 1

      this.song.publishers = revision.data.publishers
      this.song.writers = revision.data.writers
      this.song.bpm = revision.data.bpm
      this.song.artist = revision.data.artist

      this.song.x = revision.x
      this.song.y = revision.y
    },
    setX (x) {
      this.song.x = x
      this.quizing = false
    },
    getDate (date) {
      return moment(date).format('MMMM Do YYYY, h:mm:ss A')
    },
    addPublisher () {
      this.song.publishers.push({
        name: '',
        email: '',
        splitPercent: '1',
      })
    },
    addWriter () {
      this.song.writers.push({
        name: '',
        email: '',
        splitPercent: '1',
      })
    },
    addMasterOwner () {
      if (!this.master_owners) {
        this.$set(this.song, 'master_owners', []);
      }

      this.song.master_owners.push({
        name: '',
        email: '',
        splitPercent: '1',
      })
    },
    removeMasterOwner (i) {
      this.$delete(this.song.master_owners, i)
    },
    removePublisher (i) {
      this.$delete(this.song.publishers, i)
    },
    removeWriter (i) {
      this.$delete(this.song.writers, i)
    },
    Save () {
      this.loading.save = true

      this.$http.post('/admin/songs/update', {
        song: this.song
      })
        .then(({ data }) => {
          this.initialSong = this.song;

          if (data.status && data.status == 'OK') {
            this.$vs.notification({
              position: 'top-center',
              title: 'Success!',
              text: 'Song Updated',
            })

            this.$http.get('/admin/revisions/' + this.song.id)
              .then(({ data }) => {
                this.revisions = data.revisions
              })
          } else {
            for (let i in re.data.message) {
              for (let e in re.data.message[i]) {
                this.$vs.notification({
                  position: 'top-center',
                  title: 'Whoops, something went wrong',
                  text: re.data.message[i][e],
                })
              }
            }
          }

          this.loading.save = false
        })
        .catch(err => {
          console.log('error: ', err)

          this.$vs.notification({
            position: 'top-center',
            title: 'Whoops!',
            text: 'Something went wrong, check the console',
          })

          this.loading.save = false
        })
    },
  },
  watch: {
    quizing () {
      window && window.scrollTo(0, 0)
    }
  }
}
</script>

<style lang="scss" scoped>
.form-label {
  font-size: 0.875rem;
  text-transform: uppercase;
  display: inline-block;
  margin-bottom: 0.2rem;
}

#edit-song-page {
  .left-side {
    overflow-y: scroll;
    height: 100vh;
    position: sticky;
    top: 0;
  }
}

.revisions-list {
  padding-left: 10px;
  list-style: none;
}

.revision {
  font-size: 90%;
  margin-bottom: 9px;

  button {
    margin: 10px 0;
  }

  .pill {
    text-transform: none;
    font-size: 0.95rem;
  }
}

.restore-revision {
  display: none;
}

.revision:hover .restore-revision {
  display: block;
}

.wav-form-container {
  max-width: 900px;
  margin: 10px auto;
  border-radius: 25px;
  box-shadow: 0 4px 10px 0 transparentize(black, 0.95),
  0 4px 20px 10px transparentize(black, 0.9);
  padding: 10px 20px 15px;

  ::v-deep .wave-wrapper {
    position: static; /* so the timestamp is centered with the whole component */
  }
}
</style>
