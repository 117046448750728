<template>
    <div class="quiz-wrapper">
        <h2 class="text-center">Answer the following questions to determine the X coordinate</h2>

        <div class="mt-5">
            <h5 class="text-center">1. What is the mood of this song?</h5>
            <div class="pt-2" style="max-width: 500px; margin: 0 auto;">
                <button
                    v-for="(el, index) in question_map[1]"
                    :key="'q1-'+index"
                    :class="[
                        'btn',
                        'btn--block',
                        'mood-btn',
                        { 'is-selected': question_1.value == el.value }
                    ]"
                    @click="selectMood(1, { value:el.value, index:index })"
                    v-text="el.label"
                    :style="{
                        '--mood-color': `#${el.hex}`
                    }"
                />
            </div>
        </div>

        <div v-show="showQuestion2" class="mt-5" ref="question2">
            <h5 class="pt-4 text-center">2. Is it closer to an adjacent mood?</h5>
            <div class="pt-2">
                <div class="row">
                    <div
                        v-for="(el, index) in question_map[2]"
                        :key="'q2-'+index"
                        class="col text-center"
                    >
                        <button
                            class="btn mood-btn btn--block"
                            @click="selectMood(2, el)"
                            v-text="question_map[1][el].label"
                            :style="{
                                '--mood-color': `#${question_map[1][el].hex}`
                            }"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div v-show="showQuestion3" class="mt-5" ref="question3">
            <h5 class="pt-4 text-center">3. How close to the adjacent mood is it?</h5>
            <div
                v-if="question_2.index !== null"
                class="pt-2"
                style="max-width: 500px; margin: 0 auto;"
            >
                <button
                    v-for="(el, index) in [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
                    :key="'q3-'+index"
                    class="btn mood-btn mood-gradient-btn btn--block"
                    :style="getQuestion3ButtonStyle(index)"
                    v-text="`${(index * 10) * 0.5}%`"
                    @click="selectMood(3, el)"
                />
            </div>
        </div>

        <div class="mt-5">
            <p class="text-center">Question 1 value: {{ question_1.value }}</p>
            <p class="text-center">Question 2 Value: {{ question_2.value }}</p>
            <div class="text-center" v-show="question_3">
                <strong>X Coordinate: {{ question_3 }}</strong>
            </div>
        </div>

        <div v-if="question_3 !== null" class="mt-5 text-center">
            <p><button @click="$emit('set-x', question_3)" class="btn btn-primary">Finish Quiz</button></p>
            <div class="mt-3">🤞🏼 &nbsp; Don't forget to save on the next screen</div>
        </div>
    </div>
</template>

<script>

export default {
  name: 'Quiz',
  data: () => ({
    question_1: {
      index: 0,
      value: 0
    },
    question_2: {
      operator: null,
      index: null,
      value: 0
    },
    question_3: null,
    question_map: {
      1: [
        {
          label: 'angry',
          value: 0.5,
          hex: 'f03d3d'
        },
        {
          label: 'anxious',
          value: 1.5,
          hex: 'f03da6'
        },
        {
          label: 'serious',
          value: 2.5,
          hex: 'ab3df0'
        },
        {
          label: 'melancholy',
          value: 3.5,
          hex: '3d41f0'
        },
        {
          label: 'sad',
          value: 4.5,
          hex: '3d9ef0'
        },
        {
          label: 'sentimental',
          value: 5.5,
          hex: '3de1f0'
        },
        {
          label: 'nostalgic',
          value: 6.5,
          hex: '3df063'
        },
        {
          label: 'optimistic',
          value: 7.5,
          hex: 'aef03d'
        },
        {
          label: 'happy',
          value: 8.5,
          hex: 'f4f423'
        },
        {
          label: 'ecstatic',
          value: 9.5,
          hex: 'faab17'
        }
      ],
      2: [],
      3: {
        value: 0
      }
    }
  }),
  props: ['song'],
  computed: {
    showQuestion2 () {
      return this.question_1.value !== 0
    },
    showQuestion3 () {
      return !!this.question_2.operator
    }
  },
  watch: {
    showQuestion2 (show, oldShow) {
      if (show) {
        this.scrollToQuestion(this.$refs.question2)
      }
    },
    showQuestion3 (show) {
      if (show) {
        this.scrollToQuestion(this.$refs.question3)
      }
    }
  },
  methods: {
    scrollToQuestion (el, offset = 0) {
      if (!el) return

      this.$nextTick(() => {
        setTimeout(() => {
          el.scrollIntoView({
            behavior: 'smooth'
          })
        }, 150)
      })
    },
    getQuestion3ButtonStyle (i) {
      let reverse = false

      if (
        this.question_1.index == 0 && this.question_2.index == 9 ||
                this.question_2.index < this.question_1.index
      ) {
        reverse = true
      }

      // Wish I could think of a cleaner way to do this
      if (this.question_1.index == 9 && this.question_2.index == 0) {
        reverse = false
      }

      let stop = 100 - (i * 10)

      if (reverse) {
        stop = 100 - stop
      }

      const q1Color = `#${this.question_map[1][this.question_1.index].hex}`
      const q2Color = `#${this.question_map[1][this.question_2.index].hex}`

      const startColor = reverse ? q2Color : q1Color
      const endColor = reverse ? q1Color : q2Color

      const start = `${startColor} ${reverse ? '' : `${stop}%`}`
      const end = `${endColor} ${reverse ? `${stop}%` : ''}`

      return {
        '--mood-gradient': `linear-gradient(90deg, ${start}, ${end})`
      }
    },
    selectMood (question, data) {
      switch (question) {
        case 1:
          // Reset questionnair
          this.question_1.value = data.value
          this.question_1.index = data.index
          this.question_2.operator = null
          this.question_2.value = 0
          this.question_3 = null
          this.question_map[2] = []
          const results = []

          if (parseInt(data.index) !== 0) {
            results.push(parseInt(data.index) - 1)
          }
          if (parseInt(data.index) === 0) {
            results.push(9)
          }

          results.push(parseInt(data.index))

          if (parseInt(data.index) !== 9) {
            results.push(parseInt(data.index) + 1)
          }
          if (parseInt(data.index) === 9) {
            results.push(0)
          }

          this.question_map['2'] = results
          break
        case 2:
          // Reset choices for questions 2 and 3
          this.question_2.operator = null
          this.question_2.value = 0
          this.question_2.index = data

          // Middle values where 3 options are present. Not angry and Not ecstatic
          if (parseInt(this.question_1.index) !== 0 && parseInt(this.question_1.index) !== 9) {
            // Data is the index of the second circle to be clicked
            // Data will either be equal to, less than, or greater than question 1 index

            if (parseInt(this.question_2.index) > parseInt(this.question_1.index)) {
              this.question_2.operator = '+'
              this.question_2.value = this.question_1.value
            }
            if (parseInt(this.question_2.index) < parseInt(this.question_1.index)) {
              this.question_2.operator = '-'
              this.question_2.value = this.question_1.value
            }
          }

          // If angry is selected, calculate ecstatic
          if (parseInt(this.question_1.index) === 0) {
            if (parseInt(this.question_2.index) === 9) {
              this.question_2.operator = '-'
            }
            if (parseInt(this.question_2.index) === 1) {
              this.question_2.operator = '+'
            }
          }
          // If ecstatic is selected, calculate angry
          if (parseInt(this.question_1.index) === 9) {
            if (parseInt(this.question_2.index) === 8) {
              this.question_2.operator = '-'
            }
            if (parseInt(this.question_2.index) === 0) {
              this.question_2.operator = '+'
            }
          }

          if (parseInt(this.question_2.index) === parseInt(this.question_1.index)) {
            this.question_2.operator = null
          }

          this.question_2.value = this.question_1.value
          this.question_3 = this.question_2.value

          break
        case 3:
          // Data is the number to multiply by .1 to get new value
          if (this.question_2.operator == null) {
            return
          }
          const result = eval(this.question_2.value + this.question_2.operator + (data * 0.05))
          this.question_3 = Math.round((result + Number.EPSILON) * 100) / 100
          break
      }
    }
  }
}
</script>

<style scoped lang="scss">
.mood-btn {
    background-color: var(--mood-color);

    & + .mood-btn {
        margin-left: 0;
    }

    &.btn--block + .btn--block {
        margin-top: 15px;
    }
}

.mood-gradient-btn {
    padding-right: 68px;
    background-image: var(--mood-gradient)
}
</style>
