var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"quiz-wrapper"},[_c('h2',{staticClass:"text-center"},[_vm._v("Answer the following questions to determine the X coordinate")]),_c('div',{staticClass:"mt-5"},[_c('h5',{staticClass:"text-center"},[_vm._v("1. What is the mood of this song?")]),_c('div',{staticClass:"pt-2",staticStyle:{"max-width":"500px","margin":"0 auto"}},_vm._l((_vm.question_map[1]),function(el,index){return _c('button',{key:'q1-'+index,class:[
                    'btn',
                    'btn--block',
                    'mood-btn',
                    { 'is-selected': _vm.question_1.value == el.value }
                ],style:({
                    '--mood-color': ("#" + (el.hex))
                }),domProps:{"textContent":_vm._s(el.label)},on:{"click":function($event){return _vm.selectMood(1, { value:el.value, index:index })}}})}),0)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showQuestion2),expression:"showQuestion2"}],ref:"question2",staticClass:"mt-5"},[_c('h5',{staticClass:"pt-4 text-center"},[_vm._v("2. Is it closer to an adjacent mood?")]),_c('div',{staticClass:"pt-2"},[_c('div',{staticClass:"row"},_vm._l((_vm.question_map[2]),function(el,index){return _c('div',{key:'q2-'+index,staticClass:"col text-center"},[_c('button',{staticClass:"btn mood-btn btn--block",style:({
                            '--mood-color': ("#" + (_vm.question_map[1][el].hex))
                        }),domProps:{"textContent":_vm._s(_vm.question_map[1][el].label)},on:{"click":function($event){return _vm.selectMood(2, el)}}})])}),0)])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showQuestion3),expression:"showQuestion3"}],ref:"question3",staticClass:"mt-5"},[_c('h5',{staticClass:"pt-4 text-center"},[_vm._v("3. How close to the adjacent mood is it?")]),(_vm.question_2.index !== null)?_c('div',{staticClass:"pt-2",staticStyle:{"max-width":"500px","margin":"0 auto"}},_vm._l(([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]),function(el,index){return _c('button',{key:'q3-'+index,staticClass:"btn mood-btn mood-gradient-btn btn--block",style:(_vm.getQuestion3ButtonStyle(index)),domProps:{"textContent":_vm._s((((index * 10) * 0.5) + "%"))},on:{"click":function($event){return _vm.selectMood(3, el)}}})}),0):_vm._e()]),_c('div',{staticClass:"mt-5"},[_c('p',{staticClass:"text-center"},[_vm._v("Question 1 value: "+_vm._s(_vm.question_1.value))]),_c('p',{staticClass:"text-center"},[_vm._v("Question 2 Value: "+_vm._s(_vm.question_2.value))]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.question_3),expression:"question_3"}],staticClass:"text-center"},[_c('strong',[_vm._v("X Coordinate: "+_vm._s(_vm.question_3))])])]),(_vm.question_3 !== null)?_c('div',{staticClass:"mt-5 text-center"},[_c('p',[_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){return _vm.$emit('set-x', _vm.question_3)}}},[_vm._v("Finish Quiz")])]),_c('div',{staticClass:"mt-3"},[_vm._v("🤞🏼   Don't forget to save on the next screen")])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }