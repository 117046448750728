<template>
    <div>
        <div class="grouped mt-0 mb-5">
            <h5>Create A New Playlist</h5>

            <div class="form-group">
                <label for="newpltitle">Name</label>
                <input type="text" class="form-control" id="newpltitle" v-model="playlist.title">
            </div>
            <div class="form-group">
                <div class="custom-control custom-radio">
                    <input class="custom-control-input" type="radio" name="playlisttype" id="playlisttypepublic" value="public" v-model="playlist.status">
                    <label class="custom-control-label" for="playlisttypepublic">
                        Public
                    </label>
                </div>
                <div class="custom-control custom-radio">
                    <input class="custom-control-input" type="radio" name="playlisttype" id="playlisttypeprivate" value="private" v-model="playlist.status">
                    <label class="custom-control-label" for="playlisttypeprivate">
                        Private
                    </label>
                </div>
            </div>
            <button
                @click="createPlaylist"
                v-text="loading.create ? 'Saving...' : 'Create Playlist'"
                :disabled="loading.create"
                type="button"
                class="btn btn-primary"
            />
        </div>
        <div class="grouped">
            <h5>Existing Playlists</h5>
            <div class="row">
                <div class="col-6">
                    <div class="row">
                        <div class="col-12">
                            <v-select
                                multiple
                                v-model="selectedPlaylists"
                                :options="playlists"
                                label="title"
                            >
                                <template #selected-option="{ title, user }">
                                    <strong>{{ title }} - {{user.email}}</strong>
                                </template>
                                <template v-slot:option="option">
                                    {{ option.title }} - {{option.user.email}}
                                </template>
                            </v-select>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-12">
                            <button
                                @click="addToPlaylists"
                                v-text="loading.addToPlaylists ? 'Adding...' : 'Add to selected playlists'"
                                :disabled="loading.addToPlaylists || selectedPlaylists.length === 0"
                                type="button"
                                class="btn btn-primary"
                            />
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <ul class="list-group">
                        <li v-for="(playlist,i) in song_playlists" :key="i" class="list-group-item">
                            <div class="d-flex align-items-center">
                                <div class="col pl-0">
                                    <h6 v-text="playlist.title" class="mb-0" />
                                    <small>
                                        <span class="text-muted">by</span>
                                        <span v-text="playlist.user.email" />
                                    </small>
                                </div>
                                <div>
                                    <button
                                        @click="removeFromPlaylist(playlist)"
                                        v-text="loading.remove[playlist.id] ? 'Removing...' : 'Remove'"
                                        :title="'Remove song from playlist'"
                                        :disabled="loading.remove[playlist.id]"
                                        type="button"
                                        class="pill-button pill-button--outline"
                                    />
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Playists',
  props: {
    song: {
      type: Object
    }
  },
  data: () => ({
    song_playlists: [],
    playlists: [],
    selectedPlaylists: [],
    loading: {
      addToPlaylists: false,
      create: false,
      remove: {}
    },
    playlist: {
      title: '',
      status: 'public',
      description: null
    }
  }),
  created () {
    this.$http
      .get('/admin/playlists')
      .then(({ data }) => {
        if (data.status == 'OK') {
          this.playlists = data.playlists
        }
      })
      .catch(err => {
        this.$vs.notification({
          position: 'top-center',
          title: 'Whoops, something went wrong.',
          text: 'Check the console'
        })
        console.log(err)
      })

    this.$http
      .get('/admin/playlists/song/' + this.song_id)
      .then(({ data }) => {
        if (data.status == 'OK') {
          this.song_playlists = data.playlists
        }
      })
      .catch(err => {
        this.$vs.notification({
          position: 'top-center',
          title: 'Whoops, something went wrong.',
          text: 'Check the console'
        })
        console.log(err)
      })
  },
  computed: {
    song_id () {
      return this.song && this.song.id
    }
  },
  methods: {
    removeFromPlaylist (playlist) {
      this.$set(this.loading.remove, playlist.id, true);

      this.$http.post('/admin/playlists/remove-song', {
        song_id: this.song_id,
        playlist_id: playlist.id
      })
        .then(response => {
          if (response.data.status !== 'OK') {
            throw new Error('Response status was not OK');
          }

          this.$vs.notification({
            position: 'top-center',
            title: 'Success',
            text: 'Removed from playlist: ' + response.data.playlist.title
          })

          const remove_index = this.song_playlists.findIndex(playlist => playlist.id === response.data.playlist.id);

          if (remove_index >= 0) {
            this.song_playlists.splice(remove_index, 1)
          }
        })
        .catch(err => {
          this.$vs.notification({
            position: 'top-center',
            title: 'Whoops, something went wrong.',
            text: 'Check the console'
          })
          console.log(err)
        })
      .finally(() => {
        this.$set(this.loading.remove, playlist.id, false)
      })
    },
    createPlaylist () {
      if (!this.song) {
        this.$vs.notification({
          position: 'top-center',
          title: 'Whoops!',
          text: 'Song required to save playlist'
        })

        return;
      }

      this.loading.create = true

      this.$http.post('/admin/playlists/create', {
        playlist: this.playlist,
        // Endpoint requires an array of songs with 'song_id' prop
        songs: [
          {
            ...this.song,
            song_id: this.song_id
          }
        ]
      })
        .then(response => {
          if (response.data.status !== 'OK') {
            throw new Error('Response status was not OK');
          }

          this.playlist.status = 'public';
          this.playlist.title = '';
          this.playlist.description = null;

          this.$vs.notification({
            position: 'top-center',
            title: 'Success',
            text: 'New Playlist Created'
          })
          this.playlists.push(response.data.playlist)
          this.song_playlists.push(response.data.playlist)

          this.loading.create = false
        })
        .catch(err => {
          this.$vs.notification({
            position: 'top-center',
            title: 'Whoops, something went wrong.',
            text: 'Check the console'
          })
          console.log(err)
          this.loading.create = false
        })
    },
    addToPlaylists () {
      this.loading.addToPlaylists = true;

      this.$http
        .post('/admin/playlists/add-song', {
          song_id: this.song_id,
          playlists: this.selectedPlaylists
        })
        .then(response => {
          if (response.data.status !== 'OK') {
            throw new Error('Response status was not OK');
          }

          this.$vs.notification({
            position: 'top-center',
            title: 'Success',
            text: 'Added to playlist(s)'
          })

          for (const i in this.selectedPlaylists) {
            const index = this.song_playlists.findIndex(playlist => playlist.id === this.selectedPlaylists[i].id);

            if (index === -1) {
              this.song_playlists.push(this.selectedPlaylists[i])
            }
          }

          this.$set(this, 'selectedPlaylists', [])

          this.loading.addToPlaylists = false
        })
        .catch(err => {
          this.$vs.notification({
            position: 'top-center',
            title: 'Whoops, something went wrong.',
            text: 'Check the console'
          })
          console.log(err)
          this.loading.addToPlaylists = false
        })
    }
  }
}
</script>
